import { onAppEnterHook } from "@/router/onAppEnter.hook";

const PreviewMain = () => import("@/views/preview/PreviewMain.vue");
const PreviewNotFound = () => import("@/views/preview/PreviewNotFound.vue");

const LoveWallPreviewPage = () =>
    import(/* webpackChunkName: "love_app" */ "@/views/preview/LoveWallPreviewPage.vue");
const LoveFormPreviewPage = () =>
    import(/* webpackChunkName: "love_app" */ "@/views/preview/LoveFormPreviewPage.vue");
const LoveMailPreviewPage = () =>
    import(/* webpackChunkName: "love_app" */ "@/views/preview/LoveMailPreviewPage.vue");
const LegalPreviewPage = () =>
    import(/* webpackChunkName: "legal_app" */ "@/views/preview/LegalPreviewPage.vue");
const FaqPreviewPage = () =>
    import(/* webpackChunkName: "faq_app" */ "@/views/preview/FaqPreviewPage.vue");
const CookiePreviewPage = () =>
    import(/* webpackChunkName: "cookie_app" */ "@/views/preview/CookiePreviewPage.vue");
const CookieAdjustPreviewPage = () =>
    import(/* webpackChunkName: "cookie_app" */ "@/views/preview/CookieAdjustPreviewPage.vue");
const CookieInfoPreviewPage = () =>
    import(/* webpackChunkName: "cookie_app" */ "@/views/preview/CookieInfoPreviewPage.vue");
const TimerPreviewPage = () =>
    import(/* webpackChunkName: "timer_app" */ "@/views/preview/TimerPreviewPage.vue");
const CoffeePreviewPage = () =>
    import(/* webpackChunkName: "coffee_app" */ "@/views/preview/CoffeePreviewPage.vue");
const PricingPreviewPage = () =>
    import(/* webpackChunkName: "pricing_app" */ "@/views/preview/PricingPreviewPage.vue");
const PlayerPreviewPage = () =>
    import(/* webpackChunkName: "player_app" */ "@/views/preview/PlayerPreviewPage.vue");
const PlayerCertificatePreviewPage = () =>
    import(/* webpackChunkName: "player_app" */ "@/views/preview/PlayerCertificatePreviewPage.vue");
const PagePreviewPage = () =>
    import(/* webpackChunkName: "page_app" */ "@/views/preview/PagePreviewPage.vue");
const BannerPreviewPage = () =>
    import(/* webpackChunkName: "banner_app" */ "@/views/preview/BannerPreviewPage.vue");
const TickerPreviewPage = () =>
    import(/* webpackChunkName: "ticker_app" */ "@/views/preview/TickerPreviewPage.vue");

export const PREVIEW_ROUTE_NAMES = {
    notFound: "preview-not-found",
    loveWall: "preview-love-wall",
    loveForm: "preview-love-form",
    loveMail: "preview-love-mail",
    legalFooter: "preview-legal-footer",
    legalTerms: "preview-legal-terms",
    legalPrivacy: "preview-legal-privacy",
    legalAbout: "preview-legal-about",
    legalNda: "preview-legal-nda",
    faq: "preview-faq",
    cookieCookie: "preview-cookie-cookie",
    cookieAdjust: "preview-cookie-adjust",
    cookieInfo: "preview-cookie-info",
    timer: "preview-timer",
    coffee: "preview-coffee",
    pricing: "preview-pricing",
    player: "preview-player",
    playerCertificate: "preview-player-certificate",
    page: "preview-page",
    banner: "preview-banner",
    ticker: "preview-ticker",
};

export const previewRoutes = [
    {
        path: "/preview",
        component: PreviewMain,
        children: [
            {
                path: `not-found`,
                name: PREVIEW_ROUTE_NAMES.notFound,
                component: PreviewNotFound,
            },
            {
                path: `walls/:projectUuid`,
                component: LoveWallPreviewPage,
                name: PREVIEW_ROUTE_NAMES.loveWall,
                beforeEnter() {
                    onAppEnterHook("love");
                },
            },
            {
                path: `review-ask/:projectUuid`,
                component: LoveFormPreviewPage,
                name: PREVIEW_ROUTE_NAMES.loveForm,
                beforeEnter() {
                    onAppEnterHook("love");
                },
            },
            {
                path: `love-mail/:projectUuid`,
                component: LoveMailPreviewPage,
                name: PREVIEW_ROUTE_NAMES.loveMail,
                beforeEnter() {
                    onAppEnterHook("love");
                },
            },
            {
                path: `footers/:projectUuid`,
                component: LegalPreviewPage,
                name: PREVIEW_ROUTE_NAMES.legalFooter,
                beforeEnter() {
                    onAppEnterHook("legal");
                },
            },
            {
                path: `terms/:legalUuid`,
                component: LegalPreviewPage,
                name: PREVIEW_ROUTE_NAMES.legalTerms,
                beforeEnter() {
                    onAppEnterHook("legal");
                },
            },
            {
                path: `policies/:legalUuid`,
                component: LegalPreviewPage,
                name: PREVIEW_ROUTE_NAMES.legalPrivacy,
                beforeEnter() {
                    onAppEnterHook("legal");
                },
            },
            {
                path: `about/:legalUuid`,
                component: LegalPreviewPage,
                name: PREVIEW_ROUTE_NAMES.legalAbout,
                beforeEnter() {
                    onAppEnterHook("legal");
                },
            },
            {
                path: `nda/:projectUuid/:ndaUuid`,
                component: LegalPreviewPage,
                name: PREVIEW_ROUTE_NAMES.legalNda,
                beforeEnter() {
                    onAppEnterHook("legal");
                },
            },
            {
                path: `faqs/:projectUuid`,
                component: FaqPreviewPage,
                name: PREVIEW_ROUTE_NAMES.faq,
                beforeEnter() {
                    onAppEnterHook("faq");
                },
            },
            {
                path: `cookie-cookie/:projectUuid`,
                component: CookiePreviewPage,
                name: PREVIEW_ROUTE_NAMES.cookieCookie,
                beforeEnter() {
                    onAppEnterHook("cookie");
                },
            },
            {
                path: `cookie-adjust/:projectUuid`,
                component: CookieAdjustPreviewPage,
                name: PREVIEW_ROUTE_NAMES.cookieAdjust,
                beforeEnter() {
                    onAppEnterHook("cookie");
                },
            },
            {
                path: `cookie-info`,
                component: CookieInfoPreviewPage,
                name: PREVIEW_ROUTE_NAMES.cookieInfo,
                beforeEnter() {
                    onAppEnterHook("cookie");
                },
            },
            {
                path: `timers/:projectUuid`,
                component: TimerPreviewPage,
                name: PREVIEW_ROUTE_NAMES.timer,
                beforeEnter() {
                    onAppEnterHook("timer");
                },
            },
            {
                path: `coffees/:projectUuid`,
                component: CoffeePreviewPage,
                name: PREVIEW_ROUTE_NAMES.coffee,
                beforeEnter() {
                    onAppEnterHook("coffee");
                },
            },
            {
                path: `pricings/:projectUuid`,
                component: PricingPreviewPage,
                name: PREVIEW_ROUTE_NAMES.pricing,
                beforeEnter() {
                    onAppEnterHook("pricing");
                },
            },
            {
                path: `players/:projectUuid`,
                component: PlayerPreviewPage,
                name: PREVIEW_ROUTE_NAMES.player,
                beforeEnter() {
                    onAppEnterHook("player");
                },
            },
            {
                path: `players/:projectUuid/certificates/:certificateUuid`,
                component: PlayerCertificatePreviewPage,
                name: PREVIEW_ROUTE_NAMES.playerCertificate,
                beforeEnter() {
                    onAppEnterHook("player");
                },
            },
            {
                path: `pages/:projectUuid`,
                component: PagePreviewPage,
                name: PREVIEW_ROUTE_NAMES.page,
                beforeEnter() {
                    onAppEnterHook("page");
                },
            },
            {
                path: `banners/:projectUuid`,
                component: BannerPreviewPage,
                name: PREVIEW_ROUTE_NAMES.banner,
                beforeEnter() {
                    onAppEnterHook("banner");
                },
            },
            {
                path: `tickers/:projectUuid`,
                component: TickerPreviewPage,
                name: PREVIEW_ROUTE_NAMES.ticker,
                beforeEnter() {
                    onAppEnterHook("ticker");
                },
            },
        ],
    },
];
