import { makeLogger } from "@/shared/consola";

const logger = makeLogger("ticker.router");

import(/* webpackChunkName: "ticker_app" */ "./ticker.route.navigate.js");

const TickerApp = () => import(/* webpackChunkName: "ticker_app" */ "./TickerApp.vue");
const TickerProjectPage = () =>
    import(/* webpackChunkName: "ticker_app" */ "./pages/TickerProjectPage.vue");
const TickerListPage = () =>
    import(/* webpackChunkName: "ticker_app" */ "./pages/TickerListPage.vue");

// Sidebar menus:
const SidebarStyles = () =>
    import(/* webpackChunkName: "ticker_app" */ "@/apps/ticker/project/sidebar/SidebarStyles.vue");
const SidebarProduct = () =>
    import(/* webpackChunkName: "ticker_app" */ "@/apps/ticker/project/sidebar/SidebarProduct.vue");
const SidebarSettings = () =>
    import(
        /* webpackChunkName: "ticker_app" */ "@/apps/ticker/project/sidebar/SidebarSettings.vue"
    );
const SidebarEvents = () =>
    import(/* webpackChunkName: "ticker_app" */ "@/apps/ticker/project/sidebar/SidebarEvents.vue");

export const TICKER_ROUTE_NAMES = {
    project: "ticker-project",
    projectStyles: "ticker-project-styles",
    projectProduct: "ticker-project-product",
    projectSettings: "ticker-project-settings",
    projectEvents: "ticker-project-events",
    list: "ticker-list",
};

export const TICKER_PREVIEW_KIND = {
    ticker: "ticker",
    adjust: "adjust",
};

export const TICKER_FIRST_SEGMENT = "ticker";

export const tickerRoutes = [
    {
        path: `/${TICKER_FIRST_SEGMENT}`,
        component: TickerApp,
        children: [
            {
                path: "projects/:projectUuid/:openedSidebar?",
                name: TICKER_ROUTE_NAMES.project,
                component: TickerProjectPage,
                children: [
                    {
                        path: "product",
                        name: TICKER_ROUTE_NAMES.projectProduct,
                        component: SidebarProduct,
                    },
                    {
                        path: "settings",
                        name: TICKER_ROUTE_NAMES.projectSettings,
                        component: SidebarSettings,
                    },
                    {
                        path: "events",
                        name: TICKER_ROUTE_NAMES.projectEvents,
                        component: SidebarEvents,
                    },
                    {
                        path: "styles",
                        name: TICKER_ROUTE_NAMES.projectStyles,
                        component: SidebarStyles,
                    },

                    // REDIRECT:
                    {
                        path: ":notFound(.*)?",
                        redirect: ({ params }) => {
                            const { projectUuid, openedSidebar } = params;
                            logger.trace(
                                "Not found sidebar path",
                                `/${openedSidebar}/`,
                                "redirecting to named:",
                                TICKER_ROUTE_NAMES.projectEvents
                            );
                            return {
                                name: TICKER_ROUTE_NAMES.projectEvents,
                                params: { projectUuid }, // this is CRUCIAL (otherwise notFound will replace :openedSidebar)
                            };
                        },
                    },
                ],
            },
            {
                path: "projects-list",
                name: TICKER_ROUTE_NAMES.list,
                component: TickerListPage,
            },
        ],
    },
];
