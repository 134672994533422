import { makeLogger } from "@/shared/consola";

const logger = makeLogger("player.router");

import(/* webpackChunkName: "player_app" */ "./player.route.navigate.js");

const PlayerApp = () => import(/* webpackChunkName: "player_app" */ "./PlayerApp.vue");
const PlayerProjectPage = () =>
    import(/* webpackChunkName: "player_app" */ "./pages/PlayerProjectPage.vue");
const PlayerListPage = () =>
    import(/* webpackChunkName: "player_app" */ "./pages/PlayerListPage.vue");
const PlayerPanelPage = () =>
    import(/* webpackChunkName: "player_app" */ "./pages/PlayerPanelPage.vue");

// Sidebar menus:
const SidebarFiles = () =>
    import(/* webpackChunkName: "player_app" */ "@/apps/player/project/sidebar/SidebarFiles.vue");
const SidebarLayout = () =>
    import(/* webpackChunkName: "player_app" */ "@/apps/player/project/sidebar/SidebarLayout.vue");
const SidebarPaywall = () =>
    import(/* webpackChunkName: "player_app" */ "@/apps/player/project/sidebar/SidebarPaywall.vue");
const SidebarAnalitics = () =>
    import(
        /* webpackChunkName: "player_app" */ "@/apps/player/project/sidebar/SidebarAnalitics.vue"
    );
const SidebarStyles = () =>
    import(/* webpackChunkName: "player_app" */ "@/apps/player/project/sidebar/SidebarStyles.vue");
const SidebarSettings = () =>
    import(
        /* webpackChunkName: "player_app" */ "@/apps/player/project/sidebar/SidebarSettings.vue"
    );

//Panel menus:
const PanelUsers = () =>
    import(/* webpackChunkName: "player_app" */ "@/apps/player/panel/PanelUsers.vue");
const PanelCertificates = () =>
    import(/* webpackChunkName: "player_app" */ "@/apps/player/panel/PanelCertificates.vue");
const PanelLessons = () =>
    import(/* webpackChunkName: "player_app" */ "@/apps/player/panel/PanelLessons.vue");

export const PLAYER_ROUTE_NAMES = {
    project: "player-project",
    projectFiles: "player-project-files",
    projectLayout: "player-project-layout",
    projectPaywall: "player-project-paywall",
    projectAnalitics: "player-project-analitics",
    projectStyles: "player-project-styles",
    projectSettings: "player-project-settings",
    list: "player-list",
    panel: "player-panel",
    panelUsers: "player-panel-users",
    panelCertificates: "player-panel-certificates",
    panelLessons: "player-panel-lessons",
};

export const PLAYER_PREVIEW_KIND = {
    player: "player",
    certificate: "certificate",
};

export const PLAYER_FIRST_SEGMENT = "player";

export const playerRoutes = [
    {
        path: `/${PLAYER_FIRST_SEGMENT}`,
        component: PlayerApp,
        children: [
            {
                path: "projects/:projectUuid/:openedSidebar?",
                name: PLAYER_ROUTE_NAMES.project,
                component: PlayerProjectPage,
                children: [
                    {
                        path: "files",
                        name: PLAYER_ROUTE_NAMES.projectFiles,
                        component: SidebarFiles,
                    },
                    {
                        path: "layout",
                        name: PLAYER_ROUTE_NAMES.projectLayout,
                        component: SidebarLayout,
                    },
                    {
                        path: "paywall",
                        name: PLAYER_ROUTE_NAMES.projectPaywall,
                        component: SidebarPaywall,
                    },
                    {
                        path: "analitics",
                        name: PLAYER_ROUTE_NAMES.projectAnalitics,
                        component: SidebarAnalitics,
                    },
                    {
                        path: "settings",
                        name: PLAYER_ROUTE_NAMES.projectSettings,
                        component: SidebarSettings,
                    },
                    {
                        path: "styles",
                        name: PLAYER_ROUTE_NAMES.projectStyles,
                        component: SidebarStyles,
                    },

                    // REDIRECT:
                    {
                        path: ":notFound(.*)?",
                        redirect: ({ params }) => {
                            const { projectUuid, openedSidebar } = params;
                            logger.trace(
                                "Not found sidebar path",
                                `/${openedSidebar}/`,
                                "redirecting to named:",
                                PLAYER_ROUTE_NAMES.projectFiles
                            );
                            return {
                                name: PLAYER_ROUTE_NAMES.projectFiles,
                                params: { projectUuid }, // this is CRUCIAL (otherwise notFound will replace :openedSidebar)
                            };
                        },
                    },
                ],
            },
            {
                path: "projects-list",
                name: PLAYER_ROUTE_NAMES.list,
                component: PlayerListPage,
            },
            {
                path: "panel/:projectUuid",
                name: PLAYER_ROUTE_NAMES.panel,
                component: PlayerPanelPage,
                children: [
                    {
                        path: "users",
                        name: PLAYER_ROUTE_NAMES.panelUsers,
                        component: PanelUsers,
                    },
                    {
                        path: "certificates",
                        name: PLAYER_ROUTE_NAMES.panelCertificates,
                        component: PanelCertificates,
                    },
                    {
                        path: "lessons",
                        name: PLAYER_ROUTE_NAMES.panelLessons,
                        component: PanelLessons,
                    },
                    // REDIRECT:
                    {
                        path: ":notFound(.*)?",
                        redirect: ({ params }) => {
                            const { projectUuid, openedSidebar } = params;
                            logger.trace(
                                "Not found sidebar path",
                                `/${openedSidebar}/`,
                                "redirecting to named:",
                                PLAYER_ROUTE_NAMES.panelUsers
                            );
                            return {
                                name: PLAYER_ROUTE_NAMES.panelUsers,
                                params: { projectUuid }, // this is CRUCIAL (otherwise notFound will replace :openedSidebar)
                            };
                        },
                    },
                ],
            },
        ],
    },
];
